// Components/AuthenticatedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const AuthenticatedRoute = () => {
  const user = useSelector((state) => state.user);

  return user ? <Outlet /> : <Navigate to="/signin" replace />;
};

export default AuthenticatedRoute;
