// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  email: '',
  fullName: '',
  profilePictureUrl: '', // New property for profile picture URL
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return { ...state, ...action.payload };
    },
    updateProfilePicture(state, action) {
      state.profilePictureUrl = action.payload;
    },
    clearUser() {
      return initialState;
    },
    resetState() {
      return initialState;
    },
  },
});

export const { setUser, updateProfilePicture, clearUser, resetState } = userSlice.actions;
export default userSlice.reducer;
