// features/toastSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toastDetails: {
    uniqueId: '',
    toaststate: false,
    message: {
      title: '',
      description: '',
    },
    icon: 'info',
    actionButton: null, 
  },
};

const toastSlice = createSlice({
  name: 'toastSliceInfo',
  initialState,
  reducers: {
    settoastDetails: (state, action) => {
      state.toastDetails = action.payload;
    },
    resetState() {
      return initialState; // Reset the state to initial state
    },
  },
});

export const { settoastDetails, resetState } = toastSlice.actions;

export default toastSlice.reducer;
