// pdfSlice.js
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    file: null,
    error: '',
  }
const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    setPdfFile: (state, action) => {
      state.file = action.payload;
      state.error = ''; // Clear error if any
    },
    clearPdfFile: (state) => {
      state.file = null;
      state.error = ''; // Clear any existing error
    },
    setPdfError: (state, action) => {
      state.error = action.payload;
    },
    resetState() {
        return initialState; // Reset the state to initial state
    },
  },
});

export const { setPdfFile, clearPdfFile, setPdfError,resetState } = pdfSlice.actions;

export default pdfSlice.reducer;
