import React from "react";
import { FaCoins } from "react-icons/fa6";

const InsufficientCoinsModal = ({ coinBalance, onClose, onNavigateToBilling }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-80 flex items-center justify-center z-50">
      {/* Modal Content */}
      <div className="bg-white rounded-2xl shadow-2xl p-8 w-4/5 md:w-1/3 max-w-2xl">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
          <FaCoins className="text-yellow-500 text-3xl" />
          {" "} Insufficient BuddCoins
          </h2>
          <button
            className="text-gray-400 hover:text-gray-600 focus:outline-none"
            onClick={onClose}
          >
            <span className="text-2xl">&times;</span>
          </button>
        </div>

        {/* Modal Body */}
        <p className="text-gray-700 text-lg mb-8">
          You need at least <span className="font-bold text-indigo-600">10 buddy coins</span> to
          start this interview. Your current balance is{" "}
          <span className="font-bold text-indigo-600">{coinBalance}</span>.
        </p>

        {/* Modal Footer */}
        <div className="flex justify-end">
          <button
            className="bg-indigo-600 text-white font-semibold px-6 py-3 rounded-lg shadow-lg transform hover:scale-105 active:scale-95 transition-transform duration-200 focus:outline-none focus:ring-4 focus:ring-indigo-300"
            onClick={onNavigateToBilling}
          >
            Buy Coins
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientCoinsModal;
