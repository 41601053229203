// import { createSlice } from '@reduxjs/toolkit';

// const buddySlice = createSlice({
//   name: 'buddy',
//   initialState: {
//     isAgentFormVisible: false,
//     buddydetails: null,
//     AiAgentoption: false,
//     buddyList: [],
//     activeBuddy: null,
//     aitype: 'ai',
//     expandedSections: {
//       ai: false,
//       coffee: false,
//     },
//     videoCall: false,
//     audioCall: false,
//   },
//   reducers: {
//     setIsAgentFormVisible(state, action) {
//       state.isAgentFormVisible = action.payload;
//     },
//     setBuddydetails(state, action) {
//       state.buddydetails = action.payload;
//     },
//     setAiAgentoption(state, action) {
//       state.AiAgentoption = action.payload;
//     },
//     setBuddyList(state, action) {
//       state.buddyList = action.payload;
//     },
//     setActiveBuddy(state, action) {
//       state.activeBuddy = action.payload;
//     },
//     setAitype(state, action) {
//       state.aitype = action.payload;
//     },
//     setExpandedSections(state, action) {
//       state.expandedSections = action.payload;
//     },
//     setVideoCall(state, action) {
//       state.videoCall = action.payload;
//     },
//     setAudioCall(state, action) {
//       state.audioCall = action.payload;
//     },
//     // Add any additional reducers as needed
//   },
// });

// export const {
//   setIsAgentFormVisible,
//   setBuddydetails,
//   setAiAgentoption,
//   setBuddyList,
//   setActiveBuddy,
//   setAitype,
//   setExpandedSections,
//   setVideoCall,
//   setAudioCall,
// } = buddySlice.actions;

// export default buddySlice.reducer;


// Redux/Slices/buddySlice.js
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  isAgentFormVisible: false,
  buddydetails: null,
  AiAgentoption: false,
  buddyList: [],
  activeBuddy: null,
  aitype: 'ai',
  expandedSections: {
    ai: false,
    coffee: false,
  },
  videoCall: false,
  audioCall: false,
  activedetails: [],
  isDarkMode: localStorage.getItem('theme') === 'dark' ? true : false,
};

const buddySlice = createSlice({
  name: 'buddy',
  initialState,
  reducers: {
    setIsAgentFormVisible(state, action) {
      state.isAgentFormVisible = action.payload;
    },
    setBuddydetails(state, action) {
      state.buddydetails = action.payload;
    },
    setAiAgentoption(state, action) {
      state.AiAgentoption = action.payload;
    },
    setBuddyList(state, action) {
      state.buddyList = action.payload;
    },
    setActiveBuddy(state, action) {
      state.activeBuddy = action.payload;
    },
    setAitype(state, action) {
      state.aitype = action.payload;
    },
    setExpandedSections(state, action) {
      state.expandedSections = action.payload;
    },
    setVideoCall(state, action) {
      state.videoCall = action.payload;
    },
    setAudioCall(state, action) {
      state.audioCall = action.payload;
    },
    setActivedetails(state, action) {
      state.activedetails = action.payload;
    },
    toggleDarkMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('theme', state.isDarkMode ? 'dark' : 'light');
      document.documentElement.classList.toggle('dark', state.isDarkMode);
    },
    setDarkMode(state, action) {
      state.isDarkMode = action.payload; // Set dark mode state
    },
    resetState() {
      return initialState; // Reset the state to initial state
    },
  },
});

export const {
  setIsAgentFormVisible,
  setBuddydetails,
  setAiAgentoption,
  setBuddyList,
  setActiveBuddy,
  setAitype,
  setExpandedSections,
  setVideoCall,
  setAudioCall,
  setActivedetails,
  toggleDarkMode, // Export the toggle action
  setDarkMode, // Export the set action
  resetState
} = buddySlice.actions;

export default buddySlice.reducer;
