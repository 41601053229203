import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Add this for navigation
import SuccessIcon from './Icons/SuccessIcon';
import ErrorIcon from './Icons/ErrorIcon';
import InfoIcon from './Icons/InfoIcon';
import WarningIcon from './Icons/WarningIcon';
import BuddytoastIcon from './Icons/BuddytoastIcon';
import { resetState } from '../../Redux/Slices/toastSlice';
import { settoastDetails } from '../../Redux/Slices/toastSlice';

const iconMapping = {
  success: <SuccessIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  buddymsg: <BuddytoastIcon />,
};

export const Toast = ({
  toaststate,
  message,
  icon,
  uniqueKey,
  actionButton, // New prop for action button
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate
  const [progress, setProgress] = useState(100);
  const [isClosing, setIsClosing] = useState(false);
  console.log("actionButton", actionButton)

  const handleClose = () => {
    setIsClosing(true);
    setProgress(0);

    setTimeout(() => {
      dispatch(
        settoastDetails({
          uniqueId: '',
          toaststate: false,
          message: '',
          icon: '',
          actionButton: null,
        })
      );
      setIsClosing(false);
    }, 300);
  };

  const handleAction = () => {
    if (actionButton?.callback) {
      actionButton.callback(); // Execute custom callback
      dispatch(resetState());
    } else if (actionButton?.link) {
      navigate(actionButton.link); // Navigate to the specified link
      dispatch(resetState());
    }
  };

  useEffect(() => {
    if (toaststate) {
      setProgress(100);

      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev <= 0) {
            clearInterval(interval);
            handleClose();
            return 0;
          }
          return prev - 100 / 30;
        });
      }, 180);

      return () => clearInterval(interval);
    }
  }, [toaststate, dispatch]);

  const baseClasses = `fixed bottom-4 left-4 rounded-lg shadow-2xl z-50 transition-transform duration-300 ease-in-out`;

  const typeClasses = {
    success: 'bg-white dark:bg-[#22232a9e] dark:text-white text-green-800',
    error: 'bg-white dark:bg-[#22232a9e] dark:text-white text-red-800',
    info: 'bg-white dark:bg-[#22232a9e] dark:text-white text-blue-800',
    warning: 'bg-white dark:bg-[#22232a9e] dark:text-white text-yellow-800',
    buddymsg: 'bg-white dark:bg-[#22232a9e] dark:text-white text-indigo-800',
  };

  const iconColorClasses = {
    success: 'text-[#25ad58] dark:text-green-300',
    error: 'text-red-700 dark:text-[#ef4444]',
    info: 'text-blue-700 dark:text-blue-300',
    warning: 'text-yellow-700 dark:text-yellow-300',
    buddymsg: 'text-indigo-700 dark:text-indigo-300',
  };

  const progressClasses = {
    success: 'bg-[#25ad58] dark:bg-green-300',
    error: 'bg-red-700 dark:bg-[#ef4444]',
    info: 'bg-blue-700 dark:bg-blue-300',
    warning: 'bg-yellow-700 dark:bg-yellow-300',
    buddymsg: 'bg-indigo-700 dark:bg-indigo-300',
  };

  const animationClasses =
    toaststate && !isClosing
      ? 'translate-x-0 opacity-100'
      : '-translate-x-full opacity-0';

  return (
    <div
      key={uniqueKey}
      className={`overflow-hidden ${baseClasses} ${animationClasses} ${typeClasses[icon]} backdrop-blur-lg backdrop-filter backdrop-saturate-150 border dark:border-gray-600 border-gray-200`}
    >
      <div className="min-w-10 flex justify-between items-center py-3 px-4">
        <div className={`mr-3 ${iconColorClasses[icon]}`}>
          {iconMapping[icon] || <InfoIcon />}
        </div>

        <div className="flex flex-col">
          <span className="font-bold text-sm">{message.title || 'Notification'}</span>
          <span className="text-xs text-gray-500 dark:text-gray-300 mt-1">
            {message.description || 'This is a description of the notification.'}
          </span>
        

        </div>

        <div
          onClick={handleAction}
          className="ml-7 mt-[2px] font-bold cursor-pointer hover:bg-slate-50 rounded-sm hover:text-slate-400 p-1 transition ease-in-out"
        >
          {actionButton && (
  <button
    onClick={handleAction}
    className="mt-2 py-1.5 px-4 text-sm font-medium text-red-700 bg-red-100 rounded-lg shadow-md hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2 transition-all duration-300 ease-in-out"
  >
    {actionButton.text || 'Learn More'}
  </button>
)}


        </div>
      </div>
      <div className="h-1 w-full bg-gray-200 rounded mt-1">
        <div
          className={`h-full ${progressClasses[icon]} rounded`}
          style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
        />
      </div>
    </div>
  );
};
