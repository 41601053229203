import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useSelector, useDispatch } from 'react-redux';
import googleLogo from "../../../assests/Images/google.png";
import { callMockTemplatesAPI } from "../../../Services/ApiEnpoints";
import { useNavigate } from "react-router-dom";
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { settoastDetails } from "../../../Redux/Slices/toastSlice";
import { FaLightbulb } from "react-icons/fa";
import idea from "../../../assests/Images/idea.png";
import InsufficientCoinsModal from "./component/InsufficientCoinsModal";
import { FaCoins } from "react-icons/fa6";





const GooglinessInterview = () => {
    const [tipIndex, setTipIndex] = useState(0);
    const [isMicTested, setIsMicTested] = useState(false);
    const [isVideoTested, setIsVideoTested] = useState(false);
    const [permissionError, setPermissionError] = useState("");
    const [templateInfo, setTemplateInfo] = useState(null);
    const [tempId, setTempId] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMessageModal, setErrorMessageModal] = useState(false)
    const [currentCoinBalance, setCurrentCoinBalance] = useState(null)

    const userData = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        const storedTempId = localStorage.getItem("temp_id");
        if (storedTempId) {
            setTempId(storedTempId);
        }
    }, []); // Runs only once when the component mounts

    console.log("tempId....", tempId)



    useEffect(() => {
        const storedTemplateInfo = localStorage.getItem("templateInfo");
        if (storedTemplateInfo) {
            setTemplateInfo(JSON.parse(storedTemplateInfo));

        }
    }, []);



    useEffect(() => {
        const tipInterval = setInterval(() => {
            setTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
        }, 4000);

        return () => {
            clearInterval(tipInterval);
        };
    }, []);

    const testMicAndVideo = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true,
            });

            setIsMicTested(true);
            setIsVideoTested(true);
            stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
            setPermissionError(
                "Please allow microphone and camera access in your browser settings to proceed."
            );
        }
    };

    const handleStartInterview = async () => {
        if (!isMicTested || !isVideoTested) {
            setPermissionError(
                "Please test and allow access to your microphone and camera before starting."
            );
            return;
        }

        setIsProcessing(true); // Start processing
        try {
            const data = await callMockTemplatesAPI(templateInfo, userData);

            console.log("API response:", data);

            // Ensure the API response contains the necessary fields
            if (data?.access_token && data?.call_id) {
                localStorage.removeItem("templateInfo");
                localStorage.setItem("interview_callId", data.call_id);

                navigate(`/buddy/${tempId}/video-interview`, {
                    state: {
                        templateInfo: tempId,
                        templateType: "MockInterviewTemplate",
                        source: "LandingPage",
                        accessToken: data.access_token,
                        callId: data.call_id,
                        user_type: data.user_type,
                    },
                });
            } else {
                throw new Error("Incomplete response from API.");
            }
        } catch (error) {
            console.error("API error:", error);

            if (
                error.response?.status === 400 &&
                error.response?.data?.error ===
                "You need at least 10 buddy coins to start this interview."
            ) {
                // Show a modern modal for insufficient coins
                setCurrentCoinBalance(error.response.data.coinBalance)
                setErrorMessageModal(true)
            } else {
                dispatch(
                    settoastDetails({
                        uniqueId: "",
                        toaststate: true,
                        message: {
                            title: "Error",
                            description: "An error occurred while starting the interview.",
                        },
                        icon: "error",
                    })
                );
            }
        } finally {
            setIsProcessing(false); // Always stop processing
        }
    };




    const tips = [
        {
            text: "Be authentic. Showcase your unique personality.",
            icon: <EmojiEmotionsOutlinedIcon /> // Represents expressing emotions and personality.
        },
        {
            text: "Think aloud when solving problems. Share your reasoning.",
            icon: <PsychologyOutlinedIcon /> // Symbolizes reasoning, thinking, and problem-solving.
        },
        {
            text: "Ensure a stable internet connection.",
            icon: <WifiOutlinedIcon /> // Represents internet connectivity.
        },
        {
            text: "Ensure a quiet environment",
            icon: <VolumeOffOutlinedIcon /> // Indicates silence or a quiet environment.
        },
        {
            text: "Focus on your thought process over specific answers.",
            icon: <TipsAndUpdatesOutlinedIcon /> // Represents insights and focus on ideas.
        }
    ];


    const handleCloseModal = () => {
        setErrorMessageModal(false);
    };

    const handleNavigateToBilling = () => {
        console.log("Navigating to billing...");
        navigate('/billing')
    };

    return (
        <>
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-tr from-pink-100 to-blue-100 px-4">
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="w-full max-w-lg bg-white shadow-2xl rounded-3xl p-10"
                >
                    {/* Header */}
                    <div className="text-center mb-8">
                        <img src={googleLogo} alt="Google Logo" className="w-24 mx-auto mb-4" />
                        <h1 className="text-2xl font-bold text-gray-800">Googlyness Mock Interview</h1>
                        <p className="text-gray-600 text-sm mt-1">
                            Showcase how you align with Google's culture of innovation and teamwork.
                        </p>

                        <div className="mt-4 inline-flex items-center px-4 py-1 text-xs font-semibold bg-yellow-600 text-yellow-200 rounded-full shadow-md">
                            Medium
                        </div>
                    </div>

                    {/* Rotating Tips */}
                    <motion.div
                        key={tipIndex}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.4 }}
                        className="text-center mb-8"
                    >
                        <div className="flex items-center justify-center gap-3 text-gray-700 text-sm font-medium">
                            <span className="text-blue-500">{tips[tipIndex].icon}</span>
                            {tips[tipIndex].text}
                        </div>
                    </motion.div>

                    {/* Cost Message */}
                    {/* <div className="text-center mb-4 text-gray-700 text-sm font-semibold flex justify-center items-center">
                        <FaCoins className="text-yellow-400 mr-2" /> This interview costs <span className="text-blue-600 ml-1"> 10 Budcoins</span>.
                    </div> */}

                    {/* Test Microphone & Camera Button */}
                    <button
                        onClick={testMicAndVideo}
                        className="w-full px-6 py-3 mb-4 text-sm font-semibold text-blue-600 border border-blue-600 rounded-lg shadow-lg transform transition-transform active:translate-y-1 hover:shadow-2xl hover:bg-blue-50"
                    >
                        Test Microphone & Camera
                    </button>

                    {/* Permission Error */}
                    {permissionError && (
                        <div className="mb-4 text-sm text-red-600 bg-red-100 border border-red-200 rounded-md p-3">
                            {permissionError}
                        </div>
                    )}

                    {/* Start Interview Button */}
                    <button
                        onClick={handleStartInterview}
                        className={`w-full px-6 py-3 text-sm font-semibold text-white rounded-lg shadow-lg transform transition-transform active:translate-y-1 ${isMicTested && isVideoTested
                            ? "bg-blue-600 hover:bg-blue-500 hover:shadow-2xl"
                            : "bg-gray-400 cursor-not-allowed"
                            }`}
                        disabled={!isMicTested || !isVideoTested || isProcessing} // Disable button while processing
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <svg
                                    className="animate-spin h-5 w-5 mr-2 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Processing...
                            </div>
                        ) : (
                            "Start Googlyness Interview"
                        )}
                    </button>
                    <p className="text-gray-500 text-xs mt-4 text-center flex">
                        <img src={idea} className="h-4 -mr-3" /> Please note: This mock interview is for practice, not affiliated with or endorsed by Google, and is based on publicly shared questions.
                    </p>
                </motion.div>
            </div>

            {errorMessageModal && (
                <InsufficientCoinsModal
                    coinBalance={currentCoinBalance}
                    onClose={handleCloseModal}
                    onNavigateToBilling={handleNavigateToBilling}
                />
            )}
        </>
    );
};

export default GooglinessInterview;
