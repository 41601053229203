import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router

const BuddyNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full items-center justify-center bg-gray-100 dark:bg-[#16171a]">
      <div className="bg-[#f3f4f6] dark:bg-[#16171a] p-8 rounded-lg text-center space-y-2">
        <h1 className="text-4xl font-bold text-red-500 dark:text-red-400">404</h1>
        <p className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          Oops! We couldn't find the buddy you're looking for.
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Please check the buddy ID or try again later.
        </p>
        <button
          onClick={() => navigate('/buddy')}
          className="!mt-4 px-4 py-2 bg-[#5f5bcd] dark:bg-[#4e4bb3] text-white rounded-full text-sm transition-transform transform hover:scale-105 hover:bg-[#4e4bb3] dark:hover:bg-[#302e67] shadow-sm"
        >
          Go Back to Buddies
        </button>
      </div>
    </div>

  );
};

export default BuddyNotFound;
