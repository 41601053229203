import React, { useState, useEffect, useRef } from "react";
import {
    CircularProgress,
    LinearProgress,
    linearProgressClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./candidate.module.css";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { FaInfoCircle } from "react-icons/fa";
import idea from "../../../assests/Images/idea.png";


import assessment_report from "../../../assests/Images/assessment_report.png";
import faanglogo from "../../../assests/Images/Untitled_design__8___1_-removebg-preview 2.png"
import rocket_buddy from "../../../assests/Images/rocket_buddy.png";

// Import React PDF components
import {
    pdf,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import { sendPdfToBackend } from "../../../Services/ApiEnpoints";
import { useSelector } from "react-redux";
import { FiCopy } from "react-icons/fi";

// Register custom font if needed
// Font.register({
//   family: "Open Sans",
//   src: "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap",
// });

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#e0e0e0",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 8,
    },
}));

const getColorBasedOnPercentage = (perc) => {
    if (perc <= 20) return "#8886c5";
    if (perc <= 40) return "#3f51b5";
    if (perc <= 60) return "#8683db";
    if (perc <= 80) return "#7c7fd9";
    if (perc <= 100) return "#65649f";
    return "#FF9458"; // Default
};



const GenerateReport = ({ isCoinFinished, handleCloseWindow, reportData, onGenerateReport, onCancel, requestID, user_id, buddy_type }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isReportGenerated, setIsReportGenerated] = useState(false);
    const [traits, setTraits] = useState([]);
    const [pdfBlob, setPdfBlob] = useState(null);
    const isDarkMode = useSelector((state) => state.buddy.isDarkMode);
    const [isFetchingPromo, setIsFetchingPromo] = useState(false);
    const [isPromoVisible, setIsPromoVisible] = useState(false);
    let interviewType
    const storedData = localStorage.getItem("interviewData");

    if (storedData && requestID) {
        const parsedData = JSON.parse(storedData);
        interviewType = parsedData.buddy_type || buddy_type
        console.log("interviewType", interviewType)
    }



    const handleButtonClick = () => {
        window.open("https://www.producthunt.com/posts/faang", "_blank", "noopener,noreferrer");
        setIsFetchingPromo(true);
        setTimeout(() => {
            setIsFetchingPromo(false);
            setIsPromoVisible(true);
        }, 27000); // 30 seconds
    };

    const handleCopyPromoCode = () => {
        navigator.clipboard.writeText("BuddyPH5FreeCoins");
        alert("Promo code copied!");
    };


    useEffect(() => {
        if (reportData) {
            setIsLoading(false); // Stop loading when report data is available
            if (reportData.psychometric_analysis) {
                const formattedTraits = Object.keys(
                    reportData.psychometric_analysis
                ).map((key) => {
                    const perc = parseInt(reportData.psychometric_analysis[key], 10);
                    return {
                        trait: formatTraitName(key),
                        perc,
                        color: getColorBasedOnPercentage(perc),
                    };
                });
                setTraits(formattedTraits);
            }

            // Generate PDF Blob once report data is available
            generatePdfDocument(reportData);
        }
    }, [reportData]);

    const handleGenerateReport = () => {
        setIsLoading(true);
        setIsReportGenerated(true);
        onGenerateReport(); // Trigger the report generation
    };





    const formatTraitName = (key) => {
        return key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    // PDF styles
    const pdfStyles = StyleSheet.create({
        page: {
            padding: 40,
            fontFamily: "Helvetica",
            fontSize: 12,
            color: "#333",
        },
        headerContainer: {
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
        },
        headerLogo: {
            width: 130,
            height: 38,
            marginBottom: 20
        },
        headerTitle: {
            fontSize: 24,
            textAlign: "center",
            color: "#35327e",
            flex: 1,
            marginBottom: 20
        },
        section: {
            marginBottom: 20,
        },
        subHeader: {
            fontSize: 18,
            marginBottom: 10,
            color: "#35327e",
            borderBottomWidth: 1,
            borderBottomColor: "#e0e0e0",
            paddingBottom: 5,
        },
        text: {
            fontSize: 12,
            color: "#585858",
            marginBottom: 5,
            lineHeight: 1.5,
        },
        traitContainer: {
            marginBottom: 10,
        },
        traitText: {
            fontSize: 12,
            color: "#333333",
        },
        footer: {
            position: "absolute",
            bottom: 20,
            left: 40,
            right: 40,
            textAlign: "center",
            fontSize: 10,
            color: "#999",
        },
        // Additional styles...
    });

    // Function to create PDF Document
    const generatePdfDocument = async (data) => {

        // Reconstruct traits here to ensure they are available
        const pdfTraits = Object.keys(data.psychometric_analysis).map((key) => {
            const perc = parseInt(data.psychometric_analysis[key], 10);
            return {
                trait: formatTraitName(key),
                perc,
                color: getColorBasedOnPercentage(perc),
            };
        });

        const PDFDocument = (
            <Document>
                <Page size="A4" style={pdfStyles.page}>
                    {/* Header */}
                    <View style={pdfStyles.headerContainer}>
                        <Image
                            style={pdfStyles.headerLogo}
                            src={faanglogo}
                        />
                        <Text style={pdfStyles.headerTitle}>Assessment Report</Text>
                    </View>

                    {/* Job Compatibility */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.subHeader}>Job Compatibility</Text>
                        <Text style={pdfStyles.text}>
                            <Text style={{ fontWeight: "bold" }}>Strengths:</Text>{" "}
                            {data.job_compatibility.strengths}
                        </Text>
                        <Text style={pdfStyles.text}>
                            <Text style={{ fontWeight: "bold" }}>Weaknesses:</Text>{" "}
                            {data.job_compatibility.weaknesses}
                        </Text>
                    </View>

                    {/* Psychometric Analysis */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.subHeader}>Psychometric Analysis</Text>
                        {pdfTraits.map((item, index) => (
                            <View key={index} style={pdfStyles.traitContainer}>
                                <Text style={pdfStyles.traitText}>
                                    {item.trait}: {item.perc}%
                                </Text>
                            </View>
                        ))}
                    </View>

                    {/* Technical Skill Fit */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.subHeader}>Technical Skill Fit</Text>
                        <Text style={pdfStyles.text}>
                            {data.technical_skill_fit.skills_comparison}
                        </Text>
                    </View>

                    {/* Cultural Fit */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.subHeader}>Cultural Fit</Text>
                        <Text style={pdfStyles.text}>
                            <Text style={{ fontWeight: "bold" }}>Company Values Fit:</Text>{" "}
                            {data.cultural_fit.fit_with_company_values}
                        </Text>
                        <Text style={pdfStyles.text}>
                            <Text style={{ fontWeight: "bold" }}>Team Dynamics Fit:</Text>{" "}
                            {data.cultural_fit.fit_with_team_dynamics}
                        </Text>
                    </View>

                    {/* Feedback on Each Answer */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.subHeader}>Feedback on Each Answer</Text>
                        {data.feedback_on_each_answer.map((item, index) => (
                            <View key={index} style={{ marginBottom: 10 }}>
                                <Text style={pdfStyles.text}>
                                    <Text style={{ fontWeight: "bold" }}>Question:</Text>{" "}
                                    {item.question}
                                </Text>
                                <Text style={pdfStyles.text}>
                                    <Text style={{ fontWeight: "bold" }}>Answer:</Text>{" "}
                                    {item.answer}
                                </Text>
                                <Text style={pdfStyles.text}>
                                    <Text style={{ fontWeight: "bold" }}>Clarity Feedback:</Text>{" "}
                                    {item.clarity_feedback}
                                </Text>
                                <Text style={pdfStyles.text}>
                                    <Text style={{ fontWeight: "bold" }}>
                                        Technical Depth Feedback:
                                    </Text>{" "}
                                    {item.technical_depth_feedback}
                                </Text>
                                <Text style={pdfStyles.text}>
                                    <Text style={{ fontWeight: "bold" }}>
                                        Alignment with Role:
                                    </Text>{" "}
                                    {item.alignment_with_role_feedback}
                                </Text>
                            </View>
                        ))}
                    </View>

                    {/* Improvement Tips */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.subHeader}>Improvement Tips</Text>
                        {Object.keys(data.improvement_tips).map((key, index) => (
                            <View key={index} style={{ marginBottom: 10 }}>
                                <Text style={pdfStyles.text}>
                                    <Text style={{ fontWeight: "bold" }}>
                                        {formatTraitName(key)}:
                                    </Text>{" "}
                                    {data.improvement_tips[key]}
                                </Text>
                            </View>
                        ))}
                    </View>

                    {/* Footer */}

                </Page>
            </Document>
        );

        // Generate PDF Blob
        const blob = await pdf(PDFDocument).toBlob();
        setPdfBlob(blob);

        // Automatically trigger downloa
        // Send the PDF Blob to the backend
        await sendPdfToBackend(blob, user_id, requestID);
    };




    return (
        <div className="fixed inset-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center z-[1111]">
            <div
                style={{
                    background: !isDarkMode
                        ? "radial-gradient(at 19% 24%, rgb(239 204 255 / 27%) 0px, transparent 50%), radial-gradient(at 80% -10%, rgba(95, 91, 205, 0.17) 0px, rgba(79, 70, 229, 0.03) 50%) rgb(255, 255, 255)"
                        : "#5b5b5b54" // keeps background default in dark mode
                }}
                className={`bg-[#ffffffed] dark:bg-[#5b5b5b54] relative overflow-y-auto rounded-[34px] p-8 backdrop-blur-[20px] border border-[#3f3f3f] dark:border-[#3f3f3f] ${isReportGenerated
                    ? "max-w-[1200px] h-[90%]"
                    : "max-w-[700px] min-h-[70%] h-[fit] max-h-[90%] flex flex-col items-center justify-center text-center"
                    } ${isLoading ? "flex flex-col items-center justify-center text-center" : ""} w-[95%] animate-fadeIn`}
            >
                {!isReportGenerated ? (
                    <div className="flex flex-col items-center dark:bg-gray-800 rounded-xl p-6 max-w-2xl mx-auto overflow-scroll">
                        {/* Rocket Icon */}
                        <div className="mb-6">
                            {isCoinFinished ? (
                                <img
                                    src={rocket_buddy}
                                    alt="Rocket Buddy"
                                    className="w-28 h-28 object-contain opacity-90"
                                />
                            ) : (
                                <img className="h-[170px] opacity-90 mt-4" src={rocket_buddy} />
                            )}

                        </div>

                        {/* Title */}
                        <h1 className="text-4xl font-bold text-gray-800 dark:text-white mb-4">
                            Call Ended
                        </h1>

                        {/* Description */}
                        {isCoinFinished ? (
                            <p className="text-center text-lg text-gray-600 dark:text-gray-300 mb-6">
                                Out of coins? 🎉 Get <span className="font-semibold">5 more free coins</span> by upvoting us on <span className="font-bold text-orange-600">Product Hunt</span> 🚀.
                            </p>
                        ) : (
                            <p className="text-center text-lg text-gray-600 dark:text-gray-300 mb-6">
                                {buddy_type === "Coffee Call Buddy" ? (
                                    <>
                                        We enjoyed the conversation and hope to see you back soon! ☕😊
                                    </>
                                ) : (
                                    <>
                                        Great conversation! 🎉 Ready for the next step?
                                    </>
                                )}
                            </p>
                        )}

                        {/* Product Hunt CTA */}
                        {isCoinFinished && (
                            <div className="flex justify-center items-center">
                                {isPromoVisible ? (
                                    <button
                                        onClick={handleCopyPromoCode}
                                        className="flex items-center justify-between bg-gradient-to-r from-green-400 to-blue-500 text-white text-sm rounded-lg py-3 px-6 shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300 w-full max-w-xs mb-6"
                                    >
                                        <span className="font-bold text-base">BuddyPH5FreeCoins</span>
                                        <FiCopy className="ml-3 text-xl" />
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleButtonClick}
                                        disabled={isFetchingPromo}
                                        className={`flex items-center justify-center bg-gradient-to-r ${isFetchingPromo ? "from-gray-400 to-gray-600" : "from-red-500 to-orange-400"
                                            } text-white text-sm rounded-lg py-3 px-6 shadow-md hover:shadow-lg ${!isFetchingPromo && "hover:scale-105"
                                            } transition-all duration-300 w-full max-w-xs mb-6`}
                                    >
                                        {isFetchingPromo ? (
                                            <div className="flex items-center space-x-2">
                                                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                                                <span>Loading...</span>
                                            </div>
                                        ) : (
                                            <span>Get Promo Code</span>
                                        )}
                                    </button>
                                )}
                            </div>

                        )}

                        {/* Information Box */}
                       
                       
                        {buddy_type !== "Coffee Call Buddy" && (
                            <div className="flex items-center text-gray-700 dark:text-gray-300 p-4 rounded-lg w-full max-w-sm mb-6">
                               <img src={idea} className="h-5 mb-5 mr-0"/>
                                <p className="text-sm ml-[0px]">
                                    You can find the report later in the <strong>Assessment</strong> tab if you don't want to generate it now.
                                </p>
                            </div>
                        )}

                        {/* Action Buttons */}
                        <div className="flex flex-col items-center w-full space-y-4">
                            {/* Primary Button */}
                            {buddy_type !== "Coffee Call Buddy" && (
                                <button
                                    onClick={onCancel}
                                    className="w-full max-w-xs bg-indigo-600 text-white py-3 rounded-lg shadow-md hover:bg-indigo-500 hover:shadow-lg transform hover:scale-105 transition"
                                >
                                    Generate Assessment Report
                                </button>
                            )}

                            {/* Secondary Button */}
                            <button
                                onClick={handleCloseWindow}
                                className={`w-full max-w-xs py-3 rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition ${buddy_type === "Coffee Call Buddy" ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
                                    }`}
                            >
                                Return to Dashboard
                            </button>
                        </div>

                    </div>




                ) : isLoading ? (
                    <div className="flex flex-col items-center justify-center text-center">
                        <CircularProgress />
                        <p className="mt-2 text-base text-gray-400 dark:text-gray-300">Generating your report... Cooking up some magic 🪄! Please wait.</p>
                    </div>
                ) : (
                    <>
                        <h1 className="text-3xl font-bold text-[#5552a3] dark:text-white mb-6 text-center flex justify-center items-center gap-[10px]">

                            <img className="h-9 mb-2" src={assessment_report} />

                            Assessment Report
                        </h1>
                        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                            <div className="flex flex-col gap-6">
                                <Card
                                    title="Job Compatibility"
                                    content={renderJobCompatibility(reportData?.job_compatibility)}
                                />
                                <Card
                                    title="Psychometric Analysis"
                                    content={renderPsychometricAnalysis(traits)}
                                />
                                <Card
                                    title="Technical Skill Fit"
                                    content={renderTechnicalSkillFit(
                                        reportData?.technical_skill_fit
                                    )}
                                />
                                <Card
                                    title="Cultural Fit"
                                    content={renderCulturalFit(reportData?.cultural_fit)}
                                />
                            </div>
                            <div className="flex flex-col gap-6">
                                <Card
                                    title="Feedback on Each Answer"
                                    content={renderFeedback(reportData?.feedback_on_each_answer)}
                                />
                                <Card
                                    title="Improvement Tips"
                                    content={renderImprovementTips(reportData?.improvement_tips)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-8">
                            <button
                                className="bg-[#ff4d4d] text-white py-3 px-6 rounded-[8px] text-[1rem] cursor-pointer transition-colors duration-300 ease-in-out hover:bg-[#e60000]"
                                onClick={onCancel}
                            >
                                Close
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

// Reusable Card Component
const Card = ({ title, content }) => (
    <section className="bg-white dark:bg-[#1f1f1f] rounded-[15px] p-8 shadow-lg">
        <h2 className="text-[17px] font-semibold text-[#35327e] dark:text-[#a0a0ff] mb-4">
            {title}
        </h2>
        <hr className="border-0 h-px bg-[#6a66d029] my-[27px]" />{" "}
        {/* Separator line */}
        <div className="text-[14px] text-[#585858] dark:text-gray-300">
            {content}
        </div>
    </section>
);

// Render functions for reportData
const renderPsychometricAnalysis = (traits) => (
    <div className="flex flex-col gap-4">
        {traits.length === 0 ? (
            <div className="text-[1rem] text-[#c53030] text-center my-4">
                No data available
            </div>
        ) : (
            traits.map((item) => (
                <div key={item.trait} className="flex flex-col gap-2">
                    <div className="flex justify-between text-[1rem] font-medium text-[#333333] dark:text-gray-200">
                        <span>{item.trait}</span>
                        <span>{item.perc}%</span>
                    </div>
                    <BorderLinearProgress
                        variant="determinate"
                        value={item.perc}
                        sx={{
                            [`& .${linearProgressClasses.bar}`]: {
                                backgroundColor: item.color,
                            },
                        }}
                    />
                </div>
            ))
        )}
    </div>
);

const FeedbackTooltip = ({ children, onClose }) => {
    const tooltipRef = useRef(null);

    // Close the tooltip if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [tooltipRef, onClose]);

    return (
        <div
            ref={tooltipRef}
            className="flex flex-col gap-[10px] absolute top-[50px] right-0 bg-white dark:bg-[#2d2d4f] text-[#6a66d0] dark:text-[#bdbdc8] p-4 rounded-[12px] shadow-lg max-w-[300px] z-[1002]"
        >
            {children}
        </div>
    );
};

const RenderQuestionAnswer = ({ item }) => {
    const [showFeedback, setShowFeedback] = useState(false);

    const handleFeedbackToggle = () => {
        setShowFeedback(!showFeedback);
    };

    const handleCloseTooltip = () => {
        setShowFeedback(false);
    };

    return (
        <div className="flex flex-col gap-4 relative mb-8">
            {/* Overlay */}
            {showFeedback && (
                <div
                    className="fixed inset-0 w-full h-[190vh] bg-black bg-opacity-50 z-[1001]"
                    onClick={handleCloseTooltip}
                />
            )}

            {/* Question */}
            <div className="bg-[#ddddff] dark:bg-[#2d2d4f] p-4 rounded-[10px] text-[14px] text-[#585858] dark:text-gray-300 font-normal">
                <div className="text-[0.8rem] font-bold text-left">Buddy</div>
                <p>{item.question}</p>
            </div>

            {/* Answer and Feedback Button */}
            <div className="flex justify-between items-center gap-4">
                <div className="flex-1">
                    <div className="flex-1 bg-[#e8e8e8] dark:bg-[#3d3d3d] p-4 text-[14px] rounded-[10px] text-[#5b5b5b] dark:text-gray-300">
                        <div className="text-[0.8rem] font-bold text-left">You</div>
                        {item.answer}
                    </div>
                </div>

                <button
                    className="bg-[#6a66d0] text-white py-2 px-4 rounded-[8px] cursor-pointer transition-colors duration-300 ease-in-out hover:bg-[#8682ce]"
                    onClick={handleFeedbackToggle}
                >
                    Feedback
                </button>
            </div>

            {/* Tooltip for Feedback */}
            {showFeedback && (
                <FeedbackTooltip onClose={handleCloseTooltip}>
                    <div className="flex items-center gap-2 font-bold mb-2">
                        <span role="img" aria-label="bulb">
                            💡
                        </span>
                        <p>Feedback</p>
                    </div>
                    <p>
                        <strong>Clarity Feedback:</strong> {item.clarity_feedback}
                    </p>
                    <p>
                        <strong>Technical Depth Feedback:</strong>{" "}
                        {item.technical_depth_feedback}
                    </p>
                    <p>
                        <strong>Alignment with Role:</strong>{" "}
                        {item.alignment_with_role_feedback}
                    </p>
                </FeedbackTooltip>
            )}
        </div>
    );
};

const renderFeedback = (feedback) => (
    <div>
        {feedback.map((item, index) => (
            <RenderQuestionAnswer key={index} item={item} />
        ))}
    </div>
);

const renderJobCompatibility = (data) => (
    <div className="flex flex-col gap-4 sm:flex-row">
        {/* Strengths */}
        <div className="flex-1 bg-white dark:bg-[#2d2d2d] rounded-[12px] p-4 shadow-md transition-transform duration-300 ease-in-out text-[14px] text-[#585858] dark:text-gray-300 hover:-translate-y-1.5 hover:shadow-lg">
            <div className="flex items-center gap-2 mb-2">
                <div className="text-[1.5rem] text-[#2e8b57]">✔</div>
                <h3 className="text-lg font-semibold">Strengths</h3>
            </div>
            <p>{data.strengths}</p>
        </div>

        {/* Weaknesses */}
        <div className="flex-1 bg-white dark:bg-[#2d2d2d] rounded-[12px] p-4 shadow-md transition-transform duration-300 ease-in-out text-[14px] text-[#585858] dark:text-gray-300 hover:-translate-y-1.5 hover:shadow-lg">
            <div className="flex items-center gap-2 mb-2">
                <div className="text-[1.5rem] text-[#ff6f61]">✘</div>
                <h3 className="text-lg font-semibold">Weaknesses</h3>
            </div>
            <p>{data.weaknesses}</p>
        </div>
    </div>
);

const renderTechnicalSkillFit = (data) => (
    <div className="text-[14px] text-[#585858] dark:text-gray-300">
        <p>{data?.skills_comparison}</p>
    </div>
);

const renderCulturalFit = (data) => (
    <div className="flex flex-col justify-center items-center gap-[18px] text-[14px] text-[#585858] dark:text-gray-300">
        <p>
            <strong>Company Values Fit:</strong> {data?.fit_with_company_values}
        </p>
        <p>
            <strong>Team Dynamics Fit:</strong> {data?.fit_with_team_dynamics}
        </p>
    </div>
);

const formatTraitName = (key) => {
    return key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

const renderImprovementTips = (tips) => (
    <div className="flex flex-col gap-4">
        {Object.keys(tips).map((key, index) => (
            <div
                key={index}
                className="bg-[#f3f3f7] dark:bg-[#3d3d3d] rounded-[12px] p-4 shadow-md transition-transform duration-300 ease-in-out hover:-translate-y-1.5 hover:shadow-lg"
            >
                <div className="flex items-center gap-2 mb-2">
                    <div className="text-[1.25rem] text-[#0277bd] dark:text-[#80d0ff]">
                        💡
                    </div>
                    <h3
                        style={{ textTransform: "capitalize", fontWeight: 600 }}
                        className="text-lg font-semibold"
                    >
                        {formatTraitName(key)}
                    </h3>
                </div>
                <p className="text-[14px] text-[#585858] dark:text-gray-300">
                    {tips[key]}
                </p>
            </div>
        ))}
    </div>
);

export default GenerateReport;
