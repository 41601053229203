import React, { useState, useEffect, useRef } from "react";
import { MdStar, MdStarBorder } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { sendFeedback } from "../../../Services/ApiEnpoints";
import { settoastDetails } from "../../../Redux/Slices/toastSlice";
import { useDispatch } from "react-redux";

const FeedbackPopUp = ({ setFeedback, userEmail, userId, userFullName }) => {
  const [hoverIndex, setHoverIndex] = useState(0);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const modalRef = useRef(null);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setFeedback(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setFeedback]);

  const handleMouseOver = (index) => setHoverIndex(index);
  const handleMouseOut = () => setHoverIndex(0);
  const handleClick = (index) => setClickedIndex(index);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    // Validate that all required fields are filled
    if (!title || !description || clickedIndex === 0) {
      setError("All fields are required, including rating.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const feedbackData = {
      title,
      description,
      rating: clickedIndex, // assuming clickedIndex is used for rating
      userEmail,            // assuming these values are available
      userId,
      userFullName
    };

    try {
      // Submit feedback using your API function (assuming sendFeedback is defined)
      const response = await sendFeedback(feedbackData); // Make sure to pass the token here
      dispatch(settoastDetails({
        uniqueId: '',
        toaststate: true,
        message: { title: "Thank You!", description: "Your feedback has been successfully submitted. We appreciate your input!" },
        icon: 'success',
      }));

      setFeedback(false); // Close modal on success
    } catch (error) {
      // Handle error case
      setError(error.error || "Failed to submit feedback. Please try again.");
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };


  const renderStars = () => {
    return Array.from({ length: 5 }, (_, index) => index + 1).map((starIndex) => (
      <button
        key={starIndex}
        type="button"
        className="focus:outline-none transition-transform transform hover:scale-110"
        onMouseOver={() => handleMouseOver(starIndex)}
        onMouseOut={handleMouseOut}
        onClick={() => handleClick(starIndex)}
      >
        {starIndex <= (hoverIndex || clickedIndex) ? (
          <MdStar className="text-yellow-400" size={36} />
        ) : (
          <MdStarBorder className="text-gray-300 dark:text-gray-500" size={36} />
        )}
      </button>
    ));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div ref={modalRef} className="relative w-full max-w-lg mx-4 p-8 bg-white dark:bg-gray-800 rounded-2xl shadow-xl">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100">
            Share Your Feedback
          </h2>
          <button
            onClick={() => setFeedback(false)}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100 focus:outline-none transition-colors"
          >
            <AiOutlineClose size={24} />
          </button>
        </div>

        {/* Body */}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Title Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Feedback Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter a title for your feedback"
              className="w-full px-4 py-3 text-gray-800 dark:text-gray-100 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-shadow"
            />
          </div>

          {/* Description Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Your Experience <span className="text-red-500">*</span>
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Tell us about your experience"
              rows={4}
              className="max-h-[160px] w-full px-4 py-3 text-gray-800 dark:text-gray-100 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-shadow"
            />
          </div>

          {/* Rating Field */}
          <div>
            <p className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
              How likely are you to recommend us to others? <span className="text-red-500">*</span>
            </p>
            <div className="flex justify-start space-x-3">
              {renderStars()}
            </div>
          </div>

          {/* Error Message */}
          {error && <p className="text-red-500 text-sm">{error}</p>}

          {/* Footer - Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full py-[9px] text-lg font-medium text-white rounded-lg bg-[#5f5bcd] hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-300 transition-all ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
            >
              {isSubmitting ? 'Sending...' : 'Submit Feedback'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackPopUp;
