// localStorageUtils.js

const LOCAL_STORAGE_KEY = 'reduxState';

// Save state to local storage
export const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    // console.log('Saving state to local storage:', serializedState); // Debugging
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (error) {
    console.error('Could not save state to local storage', error);
  }
};

// Load state from local storage
export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    // console.log('Loaded state from local storage:', serializedState); // Debugging
    if (serializedState === null) {
      return undefined; // Return undefined to use the initial state from slice
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Could not load state from local storage', error);
    return undefined;
  }
};
