import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    loginKey: 0, // Track changes to trigger loadData
  },
  reducers: {
    incrementLoginKey(state) {
      state.loginKey += 1; // Increment loginKey to trigger loadData
    },
  },
});

export const { incrementLoginKey } = appSlice.actions;

export default appSlice.reducer;
