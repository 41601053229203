import React, { useState, useEffect, memo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';

const BuddyLoader = ({ openLoader, handleClose }) => {

  const [isDarkMode, setIsDarkMode] = useState(false);
  const isDarkMode_selector = useSelector((state) => state.buddy.isDarkMode);
  const dashboard_theme = localStorage.getItem("theme");

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const isDarkMode = storedTheme === 'dark';
    if (isDarkMode) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, [isDarkMode_selector, dashboard_theme]);

  return (
    <Backdrop
      sx={(theme) => ({
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: (isDarkMode) ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.3)', // Conditional background color
      })}
      open={openLoader}
    // onClick={handleClose}
    >
      <span>
        <svg
          width="105"  // Reduced width by 25%
          height="90"  // Reduced height by 25%
          viewBox="0 0 120 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >

          <defs>
            <linearGradient id="gradient1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2F3A8A" />
              <stop offset="100%" stopColor="#6A328D" />
            </linearGradient>
            <linearGradient id="gradient2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#35368A" />
              <stop offset="100%" stopColor="#6B2E87" />
            </linearGradient>
            <linearGradient id="gradient3" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3A3385" />
              <stop offset="100%" stopColor="#752B88" />
            </linearGradient>
            <linearGradient id="gradient4" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2F3882" />
              <stop offset="100%" stopColor="#693086" />
            </linearGradient>

            <filter id="lightShadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="1" dy="2" stdDeviation="2" flood-color="rgba(0, 0, 0, 0.15)" />
            </filter>
          </defs>

          <rect x="0" y="28" width="20" height="33" rx="10" fill="url(#gradient1)" filter="url(#lightShadow)" className="bar bar1"></rect>
          <rect x="25" y="20" width="20" height="44" rx="10" fill="url(#gradient2)" filter="url(#lightShadow)" className="bar bar2"></rect>
          <rect x="50" y="16" width="20" height="56" rx="10" fill="url(#gradient3)" filter="url(#lightShadow)" className="bar bar3"></rect>
          <rect x="75" y="22" width="20" height="43" rx="10" fill="url(#gradient4)" filter="url(#lightShadow)" className="bar bar4"></rect>
        </svg>


      </span>

      <style>
        {`
          .bar {
            animation: pulse 0.5s infinite ease-in-out;
          }
          .bar1 { animation-delay: 0s; }
          .bar2 { animation-delay: 0.1s; }
          .bar3 { animation-delay: 0.2s; }
          .bar4 { animation-delay: 0.9s; }

          @keyframes pulse {
            0%, 100% {
              transform: scaleY(1);
            }
            50% {
              transform: scaleY(1.1);
            }
          }
        `}
      </style>
    </Backdrop>
  );
};

export default BuddyLoader;
