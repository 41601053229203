import React from "react";

const EndCallWarningModal = ({ onConfirm, onCancel, user_type }) => {
  const isNewVisitor = user_type === "new visitor";

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-80 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-2xl p-8 w-full max-w-lg mx-4 text-center transform transition-all scale-105">
        {/* Modal Header */}
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {isNewVisitor
            ? "One Chance to Take This Interview for Free"
            : "End Call Confirmation"}
        </h2>
        {/* Modal Body */}
        {isNewVisitor && (
          <p className="text-gray-600 text-lg mb-6">
            If you end the call now and wish to retake it, you will need{" "}
            <span className="font-bold text-indigo-600">10 BudCoins</span>.
          </p>
        )}
         {!isNewVisitor && (
          <p className="text-gray-600 text-lg mb-6">
            Are you sure you want to end this call?{" "}
            
          </p>
        )}
        {/* Modal Footer */}
        <div className="space-y-4">
          <button
            onClick={onConfirm}
            className="w-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white font-semibold px-6 py-3 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-all"
          >
            {isNewVisitor ? "Yes, I’m sure" : "Yes, I’m sure"}
          </button>
          <button
            onClick={onCancel}
            className="w-full bg-gray-100 text-gray-800 font-semibold px-6 py-3 rounded-full hover:bg-gray-200 shadow-md hover:shadow-lg transform hover:scale-105 transition-all"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndCallWarningModal;
